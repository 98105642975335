import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { LoaderService } from '../../shared/loader/loader.service';
import { AppConfig } from '../../app.config';
import { UserService } from '../../services';
import { NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Feedback, TechnicianModel } from '../../models';

@Component({
  selector: 'feedbackrequestcreate-root',
  templateUrl: './feedbackrequestcreate.component.html'
})

export class FeedbackrequestcreateComponent {
  Model: Feedback = new Feedback();
  technicianlist: Array<TechnicianModel> = [];
  CurrentUser: any;
  OrderId: number;
  UserId: any;
  Ipaddress: any;
  Today: any;

  constructor(public dialog: MatDialog, private titleService: Title,
    private loaderService: LoaderService, private config: AppConfig,
    private service: UserService, private notifyService: NotificationService,
    private route: Router, private activeRout: ActivatedRoute) {
    const userinfo = localStorage.getItem('currentUser');
    const decryptuserinfo: any = this.config.decrypt(userinfo);
    this.CurrentUser = JSON.parse(decryptuserinfo);
    this.UserId = this.CurrentUser.Id;
    this.Ipaddress = this.CurrentUser.IpAddress;
  }

  ngOnInit(): void {
    this.Today = new Date();
    this.GetTechnicianList();
    this.activeRout.params.subscribe((params: Params) => {
      if (params['id']) {
        this.OrderId = params['id'];
      }
    });
    if (this.OrderId > 0) {
      this.GetOrderDetailById();
    }
  }

  GetTechnicianList() {
    this.loaderService.show();
    this.service.getAll(this.config.GetTechnicianListApi).subscribe(objectList => {
      this.technicianlist = objectList;
    }, error => {
      this.technicianlist = null;
      this.loaderService.hide();
      console.log('Function- GetTechnicianList;Error -' + error);
    });
    this.loaderService.hide();
  }

  GetOrderDetailById() {
    this.loaderService.show();
    this.service.getById(this.config.GetFullOrderDetailBYIDApi, this.OrderId).subscribe(objectList => {
      if(objectList && objectList.length > 0){
        this.Model = objectList;
        this.Model.Growth = '';
        this.Model.Grams = '';
        this.Model.Survival = '';
        this.Model.Density = '';
        this.Model.Salinity = '';
        this.Model.Alkalinity = '';
        this.Model.PH = '';
        this.Model.Problem = '';
        this.Model.RateUs = '';
        this.Model.TechnicianId = this.technicianlist.filter(function (node) {
          return node.Name === objectList.TechnicianName;
        })[0].Id;
      }
    }, error => {
      this.Model = null;
      this.loaderService.hide();
      console.log('Function- GetPendingOrderList;Error -' + error);
    });
    this.loaderService.hide();
  }

  Create(): void {
    this.loaderService.show();
    this.Model.CreatedOn = new Date();
    this.Model.ID = 0;
    this.Model.OrderID = Number(this.OrderId);
    this.Model.Ipaddress = '0.0.0.0';
    this.service.save(this.config.SaveFeedbackApi, this.Model).subscribe(response => {
      const msg = response.Message.message;
      const status = response.Message.status;
      if (status === 200) {
        this.route.navigate(['./thankyou']);
      }
      else {
        this.notifyService.showWarning(msg, '');
      }
      this.loaderService.hide();
    },
      error => {
        this.notifyService.showError(error.message, '');
        this.loaderService.hide();
      }
    );
  }
}

