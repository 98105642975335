
export class TankRefillModel {
    public ID: number;
    public TankId: number;
    public HatcheryId: number = 0;
    public NoOfCounts: number = 0;
    public NoOfBags: number = 0;
    public TankName: string = '';
    public QtyVar: string = '';
    public Qty: number = 0;
    public Total: number = 0;
    public SaleQty: number = 0;
    public RemainingQty: number = 0;
    public Salinity: number = 0;
    public SalinityVar: string = '';
    public BroodStockId: number = 0;
    public StageId: number = 0;
    public IsActive: boolean;
    public isCheck: boolean = false;
    public isFull: boolean = false;
    public isChange: boolean = false;
    public CreateOn: Date;
    public CreatedBy: number;
    public ModifyOn: Date;
    public ModifyBy: number;
    public StatusId: number;
    public Remarks: string;
    public class: string;
    public IpAddress: string;
    public BroodStockName: string;
    public StageName: string;
    public IsUsedInOrder: number;
    public UsedQty: number;
    public AvailQty: number = 0;
}
export class OrderModel {
    public Id: number = 0;
    public OrderedBY: number = 0;
    public OrderForHatcheryID: number = 0;
    public ExpDeliveryDate: any;
    public TechnicianID: number = 0;
    public Salinity: number = 0;
    public BroodStockId: number = 0;
    public StageID: number = 0;
    public Qty: number = 0;
    public Bonous: number = 0;
    public NetQty: number = 0;
    public PaidQty: number = 0;
    public Priority: string = 'normal';
    public IsEdit: boolean;
    public NoOfBags: number = 0;
    public NoOfCounts: number = 0;
    public PackingDate: Date;
    public FarmerName: string;
    public FarmerVillage: string;
    public ISACTIVE: boolean;
    public STATUSID: number = 0;
    public CREATEDON: Date;
    public CREATEDBY: number = 0;
    public MODIFYON: Date;
    public MODIFYBY: number = 0;
    public IPADDRESS: string;
    public FarmerMobile: string;
    public Village: string;
    public Pincode: string;
    public FarmerPincode: string;
    public UnitRate: number = 0;
    public SalesAmount: number = 0;
    public Discount: number = 0;
    public TotalAmount: number = 0;
    public AmountPaid: number = 0;
    public WaiveAmount: number = 0;
    public WaiveRemark: string;
    public TechnicianName: string;
    public TechnicianMobile: string;
    public OrderByName: string;
    public ReferenceFee: number = 0;
    public Netpackqty: number = 0;
    public BroodStockName: string = '';
    public selectedItems: number;
    public reference: number;
    public IsRefFeeCompany: boolean;
}

export class AllOrderModel {
    public Id: number;
    public Salinity: number;
    public NetQty: number;
    public FarmerName: string;
    public FarmerMobile: string;
    public FarmerVillage: string;
    public FarmerPincode: string;
    public TechnicianName: string;
    public TechnicianMobile: string;
    public OrderByName: string;
    public Netpackqty: number;
    public PackingDate: string;
    public UnitRate: number;
    public PaidQty: number;
    public SalesAmount: number;
    public Discount: number;
    public CashAmount: number;
    public BankRef: string;
    public ChequeRef: string;
    public BankAmount: number;
    public ChequeAmount: number;
    public BankDate: string;
    public ChequeDate: string;
    public ChqBankRemark: string;
    public BankRemark: string;
    public CREATEDBY: number;
    public IPADDRESS: string;
    public AmountPaid: number = 0;
    public TotalAmount: number = 0;
    public PaymentMode: string;
    public Remark: string;
    public Mode: string;
    public CompensationRemark: string;
    public CompensationRemarkAdmin: string;
    public CompensationHatcheryName: string;
    public ReferenceFee: number = 0;
    public PaymentId: number = 0;
    public Amount: number = 0;
    public StatusId: number = 0;
    public BroodStockName: string = '';
    public Qty: number = 0;
    public CompensationQty: number = 0;
    public HatcheryId: number = 0;
    public IsRefFeeCompany: boolean = false;
}
export class PaySettleModel {
    public Id: number;
    public OrderPaymentID: number;
    public Amount: number;
    public BankAmount: number;
    public ChqAmount: number;
    public BankRefNo: string;
    public ChqRefNo: string;
    public BankDate: any;
    public ChqDate: any;
    public ChqBankName: string;
    public BankRemark: string;
    public CreatedBy: number;
    public IpAddress: string;
    public WaiveRemark: string = '';
    public WaiveAmount: number;
    public CashAmount: number;
    public IsFinalSettlement: boolean;
    public ReferenceFee: number = 0;
    public IsRefFeeCompany: boolean = false;
}
export class CreditSalePassModel {
    public OrderId: number;
    public OrderById: number;
    public HatcheryId: number;
    public CreatedBy: number;
    public IpAddress: string = '';
}
export class CompensationModel {
    public OrderId: number;
    public HatcheryId: number = 0;
    public Qty: number;
    public Netpackqty: number;
    public Remark: string = '';
    public FarmerName: string;
    public FarmerMobile: string;
    public FarmerVillage: string;
    public FarmerPincode: string;
    public IpAddress: string = '';
    public HatcheryName: string = '';
    public CreatedBy : number = 0;
}

