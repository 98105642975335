import { Component } from '@angular/core';
import { AppConfig } from '../../app.config';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PassDataService, UserService } from '../../services';

@Component({
  selector: 'ngx-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent {
  DynToggleClass: any = 'sidebar';
  Users: any;
  username: any;
  rolename: any;
  designation: '';
  role: string = '';
  master = false;
  hatchery: string = '';
  ImagePath: string = '';
  HatcheryAdrs: string = '';
  loading = false;

  constructor(private service: UserService, private route: Router,
    private appconfig: AppConfig, public dialog: MatDialog, private pdService: PassDataService) {
    const userinfo = localStorage.getItem('currentUser');
    if (userinfo) {
      const decryptuserinfo: any = this.appconfig.decrypt(userinfo);
      this.Users = JSON.parse(decryptuserinfo);
      if (this.Users) {
        this.master = true;
        this.username = this.Users.Name;
        this.rolename = this.Users.Role;
        this.hatchery = this.Users.Hatchery;
        this.HatcheryAdrs = this.Users.HatcheryAdrs;
        this.role = this.Users.Code;
        this.ImagePath = this.appconfig.apiUrl + 'document/master/' + this.Users.PImage;
      }
      this.pdService.PassDataMethod(this.Users);
    }
  }
  

  ngOnInit(): void {

  }

  ToggleAction(val) {
    if(val === 'sidebar'){
      this.DynToggleClass = 'sidebar toggled';
    }
    else{
      this.DynToggleClass = 'sidebar';
    }
  }
}
