import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';

@Injectable()
export class PassDataService {
    PassDataMethod$: Observable<any>;
    private PassDataMethodSubject = new Subject<any>();

    constructor() {
        this.PassDataMethod$ = this.PassDataMethodSubject.asObservable();
    }

    PassDataMethod(data) {
        // I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.PassDataMethodSubject.next(data);
    }
}
