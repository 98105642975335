import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app.config';
import 'rxjs/Rx';

@Injectable()
export class UserService {
    constructor(private http: HttpClient, private config: AppConfig
    ) { }

    getAll(apiname): any {
        return this.http.get(this.config.apiUrl + apiname).map(res => res);
    }
    getAllDataPost(apiname): any {
        return this.http.post(this.config.apiUrl + apiname, '').map(res => res);
    }
    getById(apiname, id: any): any {
        return this.http.get(this.config.apiUrl + apiname + '/' + id).map(res => res);
    }

    getbyparameter(apiname, parameter: any): any {
        return this.http.post(this.config.apiUrl + apiname, parameter).map(res => res);
    }

    save(apiname, parameter: any): any {
        return this.http.post(this.config.apiUrl + apiname, parameter).map(res => res);
    }
    savewithfileupload(apiname, parameter: FormData) {
        return this.http.post(this.config.apiUrl + apiname, parameter).map(res => res);
    }

    // private helper methods
    private jwtwithfile(): HttpHeaders  {
        // create authorization header with jwt token
        const userinfo = localStorage.getItem('currentUser');
        const decryptuserinfo: any = this.config.decrypt(userinfo);
        const currentUser = JSON.parse(decryptuserinfo);
        if (currentUser && currentUser.tokenNumber) {
            const headers = new HttpHeaders().set('Authorization', 'Bearer ' + currentUser.tokenNumber );
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');
            return headers;
        }
    }
    private jwt(): HttpHeaders {
        // create authorization header with jwt token
        const userinfo = localStorage.getItem('currentUser');
        if (userinfo && userinfo !== null && userinfo !== 'null') {
            const decryptuserinfo: any = this.config.decrypt(userinfo);
            const currentUser = JSON.parse(decryptuserinfo);
            if (currentUser && currentUser.tokenNumber) {
                const headers = new HttpHeaders().set('Authorization', 'Bearer ' + currentUser.tokenNumber);
                return headers;
            }
        }
    }
}
