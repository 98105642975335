import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagesComponent } from '../app/views/pages/pages.component';
import { FeedbackrequestcreateComponent, forgetpasswordComponent, loginComponent, ThankyouComponent } from './views/Auth';

const routes: Routes = [
    { path: '', component: loginComponent, data: { breadcrumb: 'login'}  },
    { path: 'login', component: loginComponent, data: { breadcrumb: 'login'}  },
    { path: 'forgetpassword', component: forgetpasswordComponent, data: { breadcrumb: 'forget password'}  },
    { path: 'feedbackrequestcreate/:id', component: FeedbackrequestcreateComponent, data: { breadcrumb: 'feedback request create'}  },
    { path: 'thankyou', component: ThankyouComponent, data: { breadcrumb: 'Thank You'}  },
    { path: '', component: PagesComponent, loadChildren: () => import('../app/views/pages/pages.module').then(m => m.PagesModule) },
 ];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
