
export class Product_Request {
    public Id: number;  
    public RequestBY: number;
    public RequestTo: number;
    public ProductId: number;
    public RequestQty: number;
    public ApproveQty: number;
    public ApproveBy: number;
    public ApprovedDate: Date;
    public RejectedDate: Date;
    public TransferTo: number;
    public TransferDate: Date;
    public VendorId: number;
    public OrderToVendorDate: number
    public ReceiveQty: number;
    public IsReceiveComplete: boolean;
    public ReceiveCompletedDate: Date;
    public Amount: number;
    public FilePath: string;
    public ISACTIVE: boolean;
    public STATUSID: number;
    public CREATEDON: Date;
    public CREATEDBY: number;
    public MODIFYON: Date;
    public MODIFYBY: number;
    public IPADDRESS: string;
    public ReceiveRemark: string;
    public DueDate: string;
}
export class ProductRequest_Param {
    public RequestBY: number = 0;
    public RequestTo: number = 0;
    public Product_RequestID: number = 0;
    public ApproveQty: number = 0;
    public ReceiveQty: number = 0;
    public RequestQty: number = 0;
    public CREATEDBY: number = 0;
    public Product: string = '';
    public IPADDRESS: string = '';
    public CategoryName: string = '';
    public TransferTo: number = 0;
    public VendorId: number = 0;
    public Id: number = 0;
    public HatcheryId: number = 0;
    public StatusId: number = 0;
    public FilePath: string = '';
    public POFileName: string = '';
    public StatusName: string = '';
    public Amount: number = 0;
    public PaidAmount: number = 0;
    public DueDate: string = '';
    public HatcheryName: string = '';
    public RequestedByName: string = '';
    public VendorName: string = '';
    public VendorType: string = '';
    public Mobile: string = '';
    public PONumber: string = '';
    public UomName: string = '';
}
export class FilCreditors {
    public Product_RequestID: number = 0;
    public AccCreaditersID: number = 0;
    public VendorId: number = 0;
    public HatcheryId: number = 0;
    public UserId: number = 0;
    public Id: number = 0;
    public DateFrom: any;
    public DateTo: any;
    public CREATEDBY: number = 0;
}
export class TransPay_Param {
    public AccCreaditersID: number = 0;
    public PayMode: string = '';
    public PayTransactionNo: string = '';
    public PayDate: string = '';
    public BankName: string = '';
    public CREATEDBY: number;
    public IPADDRESS: string = '';
    public Mobile: string = '';
}
export class InternalPRModel {
    public ID: number;
    public RequestBY: number;
    public RequestTo: number;
    public ProductId: number;
    public RequestQty: number;
    public ApproveQty: number;
    public ApprovedDate: Date;
    public TransferQty: number;
    public TransferTo: number;
    public TransferDate: Date;
    public IsActive: boolean;
    public StatusId: number;
    public StatusChangeOn: Date;
    public Remarks: string;
    public UomName: string;
    public CreatedOn: Date;
    public CreatedBy: number;
    public ModifyOn: Date;
    public ModifyBy: number;
    public Ipaddress: string;
}
export class CreditorModel {
    public Id: number = 0;
    public Amount: number = 0;
    public CREATEDBY: number = 0;
    public IPADDRESS: string;
}
export class CreditorModelMulti {
    public ProductRequestIdStr: string = '';
    public PayAmount: number = 0;
    public Discount: number = 0;
    public VendorID: number = 0;
    public CREATEDBY: number = 0;
    public IPADDRESS: string;
}