import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../shared/loader/loader.service';
import { AppConfig } from '../../app.config';
import { UserService } from '../../services/user.service';
import { AuthenticationService, NotificationService } from '../../services/index';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class loginComponent implements OnInit {
  returnUrl: string;
  ipaddress: string;
  curyear: any;
  master: boolean = false;
  username: string = '';
  password: string = '';
  currentUser: any;
  isCheckInfo: any;
  DynClass: string = ' text-danger';
  Ischkremember: boolean = false;

  constructor(private route: ActivatedRoute, private service: UserService,
    private appconfig: AppConfig, private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private config: AppConfig, private notifyService: NotificationService) {
    this.master = false;
  }

  ngOnInit() {
    this.master = false;
    if (localStorage.getItem('Ischkremember')) {
      const ischk = localStorage.getItem('Ischkremember');
      const decryptischk: any = this.config.decrypt(ischk);
      this.isCheckInfo = JSON.parse(decryptischk);
      if(this.isCheckInfo){
        this.Ischkremember = this.isCheckInfo
        if (localStorage.getItem('currentUserRem')) {
          const userinfo = localStorage.getItem('currentUserRem');
          const decryptuserinfo: any = this.config.decrypt(userinfo);
          this.currentUser = JSON.parse(decryptuserinfo);
          this.username = this.currentUser.Login;
          this.password = this.currentUser.Password;
        }
      }
    }
  }

  Login() {
    this.loaderService.show();
    const encryptuserinfo: any = this.config.encrypt(JSON.stringify(this.Ischkremember));
    localStorage.setItem('Ischkremember', encryptuserinfo);
    this.authenticationService.login(this.username, this.password)
      .subscribe(
        data => {
          if (localStorage.getItem('currentUser')) {
            const userinfo = localStorage.getItem('currentUser');
            const decryptuserinfo: any = this.config.decrypt(userinfo);
            this.currentUser = JSON.parse(decryptuserinfo);
            if (this.currentUser && this.currentUser.Id > 0) {
              this.loaderService.hide();
              window.location.href = 'default';
            } else {
              this.notifyService.showWarning('Username is incorrect', '');
              this.loaderService.hide();
            }
          } else {
            this.notifyService.showWarning('Username is incorrect', '');
            this.loaderService.hide();
          }
        },
        error => {
          this.loaderService.hide();
          this.notifyService.showError(this.config.ErrorMSG, '');
          console.log('Function- login;Error -' + error);
        });
  }
}
