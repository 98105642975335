import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { PagesComponent } from '../app/views/pages/pages.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfig } from './app.config';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule, MatAutocompleteModule, MatInputModule } from '@angular/material';
import { ConfirmationDialogComponent, BreadcrumbComponent } from './shared/index';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ConfirmationDialogsService, PassDataService, UserService, AuthenticationService, ExportExcelService } from './services';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FeedbackrequestcreateComponent, forgetpasswordComponent, loginComponent, ThankyouComponent } from './views/Auth';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/loader/loader.service';

@NgModule({
  declarations: [
    AppComponent, PagesComponent, ConfirmationDialogComponent, BreadcrumbComponent,
    loginComponent, forgetpasswordComponent, FeedbackrequestcreateComponent, 
    LoaderComponent, ThankyouComponent
  ],
  entryComponents: [ConfirmationDialogComponent],
  imports: [HttpClientModule, ToastrModule.forRoot(), MatDialogModule,
    MatAutocompleteModule, MatInputModule, BrowserAnimationsModule,
    BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule,
    CarouselModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, 
      registrationStrategy: "registerImmediately" })
  ],
  providers: [ AppConfig, UserService, LoaderService, AuthenticationService, PassDataService, 
    ConfirmationDialogsService, ExportExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
