
export class Feedback {
    public ID: number = 0;
    public TechnicianId: number = 0;
    public TechnicianName: string;
    public TechnicianMobile: string;
    public FarmerName: string;
    public FarmerVillage: string;
    public FarmerPincode: string;
    public HatcheryName: string;
    public OrderDate: string;
    public Qty: string;
    public OrderID: number = 0;
    public ANS1: string;
    public ANS2: string;
    public ANS3: string;
    public ANS4: string;
    public Growth: string = '';
    public Grams: string;
    public Survival: string;
    public Density: string;
    public Salinity: string;
    public Alkalinity: string;
    public PH: string;
    public Problem: string;
    public Observations: string;
    public RateUs: string;
    public Suggestions: string;
    public TabName: string;
    public Comment: string;
    public CreatedOn: Date;
    public Ipaddress: string;
}