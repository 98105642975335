import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { LoaderService } from '../../shared/loader/loader.service';
import { AppConfig } from '../../app.config';
import { UserService } from '../../services';
import { NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Feedback, TechnicianModel } from '../../models';

@Component({
  selector: 'thankyou-root',
  templateUrl: './thankyou.component.html'
})

export class ThankyouComponent {
  Model: Feedback = new Feedback();
  technicianlist: Array<TechnicianModel> = [];
  CurrentUser: any;
  OrderId: number;
  UserId: any;
  Ipaddress: any;
  Today: any;

  constructor(public dialog: MatDialog, private titleService: Title,
    private loaderService: LoaderService, private config: AppConfig,
    private service: UserService, private notifyService: NotificationService,
    private route: Router, private activeRout: ActivatedRoute) {
    const userinfo = localStorage.getItem('currentUser');
    const decryptuserinfo: any = this.config.decrypt(userinfo);
    this.CurrentUser = JSON.parse(decryptuserinfo);
    this.UserId = this.CurrentUser.Id;
    this.Ipaddress = this.CurrentUser.IpAddress;
  }

  ngOnInit(): void {
    this.Today = new Date();
  }
}

