
export class CashAddModel {
    public ID: number;  
    public FROMID: number;
    public TOID: number = 0;
    public CrAMOUNT: number;
    public DrAMOUNT: number;
    public ISACTIVE: boolean;
    public STATUSID: number;
    public CREATEDON: Date;
    public CREATEDBY: number;
    public RECEIVEDATE: Date;
    public MODIFYON: Date;
    public MODIFYBY: number;
    public IPADDRESS: string;
    public REMARK: string;
}

export class CashExpenseModel {
    public ID: number;  
    public HATCHERYID: number = 0;
    public PRODUCTID: number = 0;
    public QUANTITY: number = 0;
    public EXPENSENAME: string = '';
    public ExpenseVendor: string;
    public AMOUNT: number;
    public REMARK: string;
    public UOM: string;
    public ApproveBy: number;
    public ApproveDate: Date;
    public STATUSID: number;
    public CREATEDON: Date;
    public CREATEDBY: number;
    public MODIFYON: Date;
    public MODIFYBY: number;
    public IPADDRESS: string;
    public ISACTIVE: boolean;
    public IsCreditPaid: boolean;
}
