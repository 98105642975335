
export class Enquiry {
  public Id: number;
  public name: string;
  public email: string;
  public subject: string = '';
  public message: string;
  public isdel: boolean = false;
}

export class Subscribe {
  public email: string;
  public isactive: boolean = false;
}

export class User {
  public username: string = '';
  public role: string = '';
  designation: string;
  ISDELETE: boolean = false;
}

export class UserModel {
  public Id: number;
  public Login: string = '';
  public Password: string = '';
  public Name: string = '';
  public RoleId: number = 0;
  public Mobile: string = '';
  public Email: string = '';
  public HatcheryId: number = 0;
  ISDELETE: boolean = false;
  public PImage: string = '';
  public IsActive: boolean;
  public CreateOn: Date;
  public CreatedBy: number;
  public ModifyOn: Date;
  public ModifyBy: number;
  public StatusId: number;
  public StatusChangeOn: Date;
  public Remarks: string = '';
  public IpAddress: string = '';
  public ApprovedBy: number;
  public ApprovedOn: Date;
  public CrWallet: number;
  public DrWallet: number;
  public Role: string = '';
  public Code: string = '';
  public Hatchery: string = '';
  public Otp: string = '';
  public Flag: number = 0;
}

export class HatcheryModel {
  ID: number = 0;
  Name: string = '';
  Address: string = '';
  RegistrationNo: string = '';
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  CreateOn: Date;
  CreatedBy: number;
  ModifyOn: Date;
  ModifyBy: number;
  StatusId: number = 2;
  StatusChangeOn: Date;
  Remarks: string = '';
  IpAddress: string = '';
  ApprovedBy: number;
  ApprovedOn: Date;
}

export class CategoryModel {
  Id: number = 0;
  Name: string = '';
  Code: string = '';
  UomId: number = 0;
  StatusId: number = 0;
  StatusChangeOn: Date;
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  Remarks: string = '';
  CreatedOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  Ipaddress: string = '';
}
export class UnitModel {
  Id: number = 0;
  Name: string = '';
  Code: string = '';
  StatusId: number = 0;
  StatusChangeOn: Date;
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  Remarks: string = '';
  CreatedOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  Ipaddress: string = '';
}
export class VendorModel {
  Id: number = 0;
  Name: string;
  CompanyName: string;
  Mobile: string;
  Email: string;
  Address: string;
  VendorType: string = '';
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  CreateOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  StatusId: number = 0;
  StatusChangeOn: Date;
  Remarks: string = '';
  IpAddress: string = '';
  CreditPeriodVar: string = '';
  CreditPeriod: number = 0;
  ApprovedBy: number = 0;
  ApprovedOn: Date;
}
export class StageModel {
  Id: number = 0;
  Name: string = '';
  Code: string = '';
  StatusId: number = 0;
  StatusChangeOn: Date;
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  Remarks: string = '';
  CreatedOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  Ipaddress: string = '';
}
export class SourceModel {
  Id: number = 0;
  Name: string = '';
  Code: string = '';
  StatusId: number = 0;
  StatusChangeOn: Date;
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  Remarks: string = '';
  CreatedOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  Ipaddress: string = '';
}
export class ProductModel {
  Id: number;
  Name: string;
  CategoryId: number = 0;
  UOM: number = 0;
  PImage: string;
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  CreateOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  StatusId: number = 0;
  StatusChangeOn: Date;
  Remarks: string = '';
  ProdType: string = '';
  IpAddress: string = '';
  ApprovedBy: number = 0;
  ApprovedOn: Date;
}

export class TechnicianModel {
  Id: number = 0;
  Name: string;
  Mobile: string
  Email: string;
  Address: string;
  IsActive: boolean = true;
  ISDELETE: boolean = false;
  IsFeedbackform: boolean = true;
  CreateOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  StatusId: number = 1;
  StatusChangeOn: Date;
  Remarks: string = '';
  IpAddress: string = '';
  ApprovedBy: number = 0;
  ApprovedOn: Date;
  POSTBHLIST: UserModel[];
}

export class TankModel {
  ID: number = 0;
  Name: string;
  HatcheryId: number = 0;
  Code: string;
  IsActive: boolean = true;
  CreateOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  StatusId: number = 2;
  Remarks: string = '';
  IpAddress: string = '';
}

export class ProductMappingModel {
  Id: number = 0;
  ProdId: number = 0;
  RoleIDStr: string = '';
  VendorIDStr: string = '';
  Product: string = '';
  RoleList: any;
  ISDELETE: boolean = false;
  VendorList: any;
  IsActive: boolean = true;
  CreateOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  StatusId: number = 1;
  StatusChangeOn: Date;
  Remarks: string = '';
  IpAddress: string = '';
  ApprovedBy: number = 0;
  ApprovedOn: Date;
}

export class IncomeModel {
  ID: number = 0;
  HATCHERYID: number = 0;
  PRODUCTID: number = 0;
  QTY: number = 0;
  AMOUNT: number = 0;
  STATUSID: number = 2;
  CREATEDON: Date;
  CREATEDBY: number = 0;
  Remarks: string = '';
  IPADDRESS: string = '';
  ISACTIVE: boolean = true;
}
export class RoleModel {
  Id: number = 0;
  Name: string = '';
  Code: string = '';
  StatusId: number = 0;
  StatusChangeOn: Date;
  IsActive: boolean = true;
  Remarks: string = '';
  CreatedOn: Date;
  CreatedBy: number = 0;
  ModifyOn: Date;
  ModifyBy: number = 0;
  Ipaddress: string = '';
}

export class Data {
  compname: string;
  amount: string;
  monthnames: string;
  years: number;
  Today: number = 0;
  Weekly: number = 0;
  Monthly: number = 0;
  Yearly: number = 0;
}

export class FbReqAccessModel {
  Id: number = 0;
  day1: number;
  day2: number;
  day3: number;
  day4: number;
  timefrom: string = '';
  timeto: string = '';
  isActive: boolean = false;
}