import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../../services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';
import { LoaderService } from '../../shared/loader/loader.service';
import { NotificationService } from '../../services/notification.service';
import { User } from '../../models/master';

@Component({
  selector: 'forgetpassword',
  templateUrl: './forgetpassword.component.html'
})
export class forgetpasswordComponent implements OnInit {
  model: any = {};
  title = ':: Login ::';
  returnUrl: string;
  currentUser: User;
  ipaddress: string;
  mobileno: string = '';
  constructor(private notifyService: NotificationService, private config: AppConfig,
    private service: UserService, private loaderService: LoaderService, private route: Router) {
      
    }

  ngOnInit() {
    localStorage.removeItem('currentUser');
    
  }

  ForgotPassword() {
    this.loaderService.show();
    if(this.mobileno === ''){
      this.notifyService.showWarning("Please enter Email address", '');
      this.loaderService.hide();
      return;
    }
    this.service.getById(this.config.ForgotPasswordApi, this.mobileno).subscribe(objectlist => {
      this.model = objectlist;
      if(objectlist.Msg == 'Fail'){
        this.notifyService.showWarning("Please check email address", '');
        this.loaderService.hide();
      }
      else if(objectlist.Msg !== 'Success'){
        this.notifyService.showWarning(objectlist.Msg, '');
        this.loaderService.hide();
      }
      else{
        this.notifyService.showSuccess("We have sent password to your email address " + objectlist.Result, '');
        this.mobileno = '';
        this.loaderService.hide();
        this.route.navigate(['./login']);
      }
    }, error => { this.loaderService.hide(); });
  }
}
