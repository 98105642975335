import * as urldata from '../assets/config/config.json';
import * as CryptoJS from 'crypto-js';
const webApiBaseUrl: any = urldata.default.webApiBaseUrl;

const key = CryptoJS.enc.Utf8.parse('7061737323313233');
const iv = CryptoJS.enc.Utf8.parse('7061737323313233');
export class AppConfig {
// Public Messages
public readonly ErrorMSG = 'The system is experiencing technical difficulties please ' +
  'refresh the page if the error persists then please login again';
// SIMS SSO USER LOGIN
public readonly userverification = 'api/Login/UserVerfication';
// END
// Login and Product Services
public readonly apiUrl = webApiBaseUrl;
public readonly LoginApi = 'api/Master/Login';
public readonly ForgotPasswordApi = 'api/Master/ForgotPassword';

// #region hatchery
public readonly SaveHatcheryApi = 'api/Master/SaveHatchery';
public readonly GetHatcheryDetailByIdApi = 'api/Master/GetHatcheryDetailById';
public readonly GetHatcheryListApi = 'api/Master/GetHatcheryList';
public readonly GetHatcheryListByUserIdApi = 'api/Master/GetHatcheryListByUserId';
public readonly UpdateHatcheryApi = 'api/Master/UpdateHatchery';
public readonly DeleteHatcheryApi = 'api/Master/DeleteHatchery';
//#endregion

// #region Unit
public readonly SaveUOMApi = 'api/Master/SaveUOM';
public readonly GetUOMDetailByIdApi = 'api/Master/GetUOMDetailById';
public readonly GetUOMListApi = 'api/Master/GetUOMList';
public readonly UpdateUOMApi = 'api/Master/UpdateUOM';
public readonly DeleteUOMApi = 'api/Master/DeleteUOM';
//#endregion

// #region FbReqAccess
public readonly GetFbReqAccessApi = 'api/Master/GetFbReqAccess';
public readonly UpdateFbReqAccessApi = 'api/Master/UpdateFbReqAccess';
//#endregion

// #region Vendor
public readonly SaveVendorApi = 'api/Master/SaveVendors';
public readonly GetVendorDetailByIdApi = 'api/Master/GetVendorsDetailById';
public readonly GetVendorListApi = 'api/Master/GetVendorsList';
public readonly GetAllVendorListApi = 'api/Master/GetAllVendorList';
public readonly UpdateVendorApi = 'api/Master/UpdateVendors';
public readonly DeleteVendorApi = 'api/Master/DeleteVendors';
//#endregion

// #region Category
public readonly SaveCategoryApi = 'api/Master/SaveCategory';
public readonly GetCategoryDetailByIdApi = 'api/Master/GetCategoryDetailById';
public readonly GetCategoryListApi = 'api/Master/GetCategoryList';
public readonly UpdateCategoryApi = 'api/Master/UpdateCategory';
public readonly DeleteCategoryApi = 'api/Master/DeleteCategory';
//#endregion

// #region Stage
public readonly SaveStageApi = 'api/Master/SaveStage';
public readonly GetStageDetailByIdApi = 'api/Master/GetStageDetailById';
public readonly GetStageListApi = 'api/Master/GetStageList';
public readonly UpdateStageApi = 'api/Master/UpdateStage';
public readonly DeleteStageApi = 'api/Master/DeleteStage';
//#endregion

// #region BroodStock
public readonly SaveBroodStockApi = 'api/Master/SaveBroodStock';
public readonly GetBroodStockDetailByIdApi = 'api/Master/GetBroodStockDetailById';
public readonly GetBroodStockListApi = 'api/Master/GetBroodStockList';
public readonly UpdateBroodStockApi = 'api/Master/UpdateBroodStock';
public readonly DeleteBroodStockApi = 'api/Master/DeleteBroodStock';
//#endregion

// #region GetOutstandingAmtAdmin
public readonly GetOutstandingAmtAdminApi = 'api/Master/GetOutstandingAmtAdmin';
//#endregion

// #region Product
public readonly SaveProductApi = 'api/Master/SaveProduct';
public readonly GetProductDetailByIdApi = 'api/Master/GetProductDetailById';
public readonly GetProductListApi = 'api/Master/GetProductList';
public readonly GetProductList_WOMISApi = 'api/Master/GetProductList_WOMIS';
public readonly UpdateProductApi = 'api/Master/UpdateProduct';
public readonly DeleteProductApi = 'api/Master/DeleteProduct';
//#endregion

// #region Technician
public readonly SaveTechnicianApi = 'api/Master/SaveTechnician';
public readonly GetTechnicianDetailByIdApi = 'api/Master/GetTechnicianDetailById';
public readonly GetTechnicianListApi = 'api/Master/GetTechnicianList';
public readonly GetTechnicianByIdListApi = 'api/Master/GetTechnicianByIdList';
public readonly UpdateTechnicianApi = 'api/Master/UpdateTechnician';
public readonly DeleteTechnicianApi = 'api/Master/DeleteTechnician';
public readonly ApproveTechnicianApi = 'api/Master/ApproveTechnician';
public readonly StopFeedbackTechnicianApi = 'api/Master/StopFeedbackTechnician';
//#endregion

// #region Role
public readonly SaveRoleApi = 'api/Master/SaveRole';
public readonly GetRoleDetailByIdApi = 'api/Master/GetRoleDetailById';
public readonly GetRoleListApi = 'api/Master/GetRoleList';
public readonly UpdateRoleApi = 'api/Master/UpdateRole';
public readonly DeleteRoleApi = 'api/Master/DeleteRole';
public readonly GetPageByRoleIdApi = 'api/Master/GetPageByRoleId';
public readonly AddPageAuthApi = 'api/Master/AddPageAuth';
public readonly GetPageAuthByRoleApi = 'api/Master/GetPageAuthByRole';
//#endregion

// #region VendorMapProduct
public readonly SaveProductMappingApi = 'api/Master/SaveProductMapping';
public readonly GetProductMappingDetailByIdApi = 'api/Master/GetProductMappingDetailById';
public readonly GetProductMappingDetailByRoleIdApi = 'api/Master/GetProductMappingDetailByRoleId';
public readonly GetProductMappingDetailByVendorAndRoleIDApi = 'api/Master/GetProductMappingDetailByVendorAndRoleID';
public readonly GetProductMappingListApi = 'api/Master/GetProductMappingList';
public readonly UpdateProductMappingApi = 'api/Master/UpdateProductMapping';
public readonly DeleteProductMappingApi = 'api/Master/DeleteProductMapping';
public readonly GetProductMappingVendorsListApi = 'api/Master/GetProductMappingVendorsList';
//#endregion

// #region Users
public readonly SaveUsersApi = 'api/Master/SaveUsers';
public readonly GetUsersDetailByIdApi = 'api/Master/GetUsersDetailById';
public readonly GetUsersListApi = 'api/Master/GetUsersList';
public readonly UpdateUsersApi = 'api/Master/UpdateUsers';
public readonly DeleteUsersApi = 'api/Master/DeleteUsers';
public readonly ResetPasswordApi = 'api/Master/ResetPassword';
public readonly GetUsersListByRoleApi = 'api/Master/GetUsersListByRole';
//#endregion

// #region ProductRequest
public readonly SaveProductRequestApi = 'api/Process/SaveProductRequest';
public readonly GetProductRequestPendingByAdminApi = 'api/Process/GetProductRequestPendingByAdmin';
public readonly GetProductRequestPendingByBHApi = 'api/Process/GetProductRequestPendingByBH';
public readonly ApproveProductRequestApi = 'api/Process/ApproveProductRequest';
public readonly RejectProductRequestApi = 'api/Process/RejectProductRequest';
public readonly GetProductRequestPendingByIDApi = 'api/Process/GetProductRequestPendingByID';
public readonly GetProductRequestOrderPendingApi = 'api/Process/GetProductRequestOrderPending';
public readonly SaveReceiveOrderProductRequestApi = 'api/Process/SaveReceiveOrderProductRequest';
public readonly TransferProductRequestApi = 'api/Process/TransferProductRequest';
public readonly GetProductRequestByIDApi = 'api/Process/GetProductRequestByID';
public readonly ReceiveProductOrderRequestApi = 'api/Process/ReceiveProductOrderRequest';
public readonly GetPendingProcessQtyPHApi = 'api/Process/GetPendingProcessQtyPH';
public readonly GetPendingSettelmentPHApi = 'api/Process/GetPendingSettelmentPH';
public readonly ApproveFinalProcessByBHApi = 'api/Process/ApproveFinalProcessByBH';
public readonly SaveProductRecieveFinalProcessbyPHApi = 'api/Process/SaveProductRecieveFinalProcessbyPH';
public readonly ApproveFinalProcessProcessAmtApi = 'api/Process/ApproveFinalProcessProcessAmt';
public readonly RejectFinalProcessProcessAmtApi = 'api/Process/RejectFinalProcessProcessAmt';
public readonly GetCreaditersPendingRequestApi = 'api/Process/GetCreaditersPendingRequest';
public readonly SaveCreditorAmountApi = 'api/Process/SaveCreditorAmount';
public readonly SaveCreditorAmountMultiApi = 'api/Process/SaveCreditorAmountMulti';
public readonly GetCreaditersPendingRequestforACApi = 'api/Process/GetCreaditersPendingRequestforAC';
public readonly SaveCreditorAmountByACApi = 'api/Process/SaveCreditorAmountByAC';
public readonly GetProductRequestByVendorPHApi = 'api/Process/GetProductRequestByVendorPH';
public readonly GetFinalProductRequestByVendorPHApi = 'api/Process/GetFinalProductRequestByVendorPH';
public readonly DeleteProductRequestApi = 'api/Process/DeleteProductRequest';
public readonly ExportProductRequestApi = 'api/Process/ExportProductRequest';
public readonly ProductRateChangeHistoryListApi = 'api/Process/ProductRateChangeHistoryList';
public readonly UpdateProductRateChangeStatusApi = 'api/Process/UpdateProductRateChangeStatus';

//#endregion

// #region InternalProductRequest
public readonly SaveInternalProductRequestApi = 'api/InternalPR/SaveInternalProductRequest';
public readonly GetMaxHatcheryProductQtyApi = 'api/InternalPR/GetMaxHatcheryProductQty';
public readonly GetInternalPRPendingByReqIDApi = 'api/InternalPR/GetInternalPRPendingByReqID';
public readonly GetInternalPRPendingByReqTOIdApi = 'api/InternalPR/GetInternalPRPendingByReqTOId';
public readonly ApproveInternalProductRequestApi = 'api/InternalPR/ApproveInternalProductRequest';
public readonly TransferInternalProductRequestApi = 'api/InternalPR/TransferInternalProductRequest'
public readonly ApproveForTransferIPRApi = 'api/InternalPR/ApproveForTransferIPR';
public readonly GetTransferUsersForPIRApi = 'api/InternalPR/GetTransferUsersForPIR';
public readonly RejectForTransferIPRApi = 'api/InternalPR/RejectForTransferIPR';
public readonly AcceptForTransferIPRApi = 'api/InternalPR/AcceptForTransferIPR';
public readonly SendEmailToVendorApi = 'api/InternalPR/SendEmailToVendor';
//#endregion

// #region CashFlow
public readonly GetCashAddListApi = 'api/CashFlow/GetCashAddList';
public readonly GetCashAddDetailByIdApi = 'api/CashFlow/GetCashAddDetailById';
public readonly SaveCashAddApi = 'api/CashFlow/SaveCashAdd';
public readonly UpdateCashAddApi = 'api/CashFlow/UpdateCashAdd';
public readonly ApproveCashAddApi = 'api/CashFlow/ApproveCashAdd';
public readonly DeleteCashAddApi = 'api/CashFlow/DeleteCashAdd';
public readonly GetCashExpenseListApi = 'api/CashFlow/GetCashExpenseList'
public readonly GetCashExpenseDetailByIdAPi = 'api/CashFlow/GetCashExpenseDetailById';
public readonly SaveCashExpenseApi = 'api/CashFlow/SaveCashExpense';
public readonly UpdateCashExpenseApi = 'api/CashFlow/UpdateCashExpense';
public readonly DeleteCashExpenseApi = 'api/CashFlow/DeleteCashExpense';
public readonly ApproveCashExpenseApi = 'api/CashFlow/ApproveCashExpense';
public readonly GetUserBalanceApi = 'api/CashFlow/GetUserBalance';
public readonly UpdateCreditExpensePayApi = 'api/CashFlow/UpdateCreditExpensePay';
//#endregion

// #region TankRefill
public readonly SaveTankRefillApi = 'api/TankRefill/SaveTankRefill';
public readonly SaveTankMoveApi = 'api/TankRefill/SaveTankMove';
public readonly GetTankRefillListApi = 'api/TankRefill/GetTankRefillList';
public readonly GetAlotTankListApi = 'api/TankRefill/GetAlotTankList';
public readonly GetAllTankRefillListApi = 'api/TankRefill/GetAllTankRefillList';
public readonly GetTankRefillDetailByIdApi = 'api/TankRefill/GetTankRefillDetailById';
public readonly ResetTankApi = 'api/TankRefill/ResetTank';
public readonly TankRefillUResetApi = 'api/TankRefill/TankRefillUReset';
//#endregion

// #region Tank
public readonly GetTankListApi = 'api/Master/GetTankList';
public readonly GetTankListByHatcheryApi = 'api/Master/GetTankListByHatchery';
public readonly SaveTankApi = 'api/Master/SaveTank';
public readonly GetTankDetailByIdApi = 'api/Master/GetTankDetailById';
public readonly UpdateTankApi = 'api/Master/UpdateTank';
public readonly DeleteTankApi = 'api/Master/DeleteTank';
//#endregion

// #region Order
public readonly SaveTblOrderApi = 'api/TblOrder/SaveTblOrder';
public readonly GetTblOrderDetailByIdApi = 'api/TblOrder/GetTblOrderDetailById';
public readonly GetTblOrderListApi = 'api/TblOrder/GetTblOrderList';
public readonly GetBookFarmerListApi = 'api/TblOrder/GetBookFarmerList';
public readonly ExportOrderDetailApi = 'api/TblOrder/ExportOrderDetail';
public readonly GetTblOrderListForACApi = 'api/TblOrder/GetTblOrderListForAC';
public readonly GetOrderListByTankIDApi = 'api/TblOrder/GetOrderListByTankID';
public readonly UpdateTblOrderApi = 'api/TblOrder/UpdateTblOrder';
public readonly DeleteTblOrderApi = 'api/TblOrder/DeleteTblOrder';
public readonly SaveBookTankApi = 'api/TblOrder/SaveBookTank';
public readonly SavePackOrderApi = 'api/TblOrder/SavePackOrder';
public readonly GetFullOrderDetailBYIDApi = 'api/TblOrder/GetFullOrderDetailBYID';
public readonly GetBookedTankOrderListApi = 'api/TblOrder/GetBookedTankOrderList';
public readonly SaveOrderPaymentApi = 'api/TblOrder/SaveOrderPayment';
public readonly SaveCompensationApi = 'api/TblOrder/SaveCompensation';
public readonly SaveOrderApproveByACApi = 'api/TblOrder/SaveOrderApproveByAC';
public readonly SaveOrderApproveByADApi = 'api/TblOrder/SaveOrderApproveByAD';
public readonly GetPendingOrderListAdminApi = 'api/TblOrder/GetPendingOrderListAdmin';
public readonly GetPendingObjectionListApi = 'api/TblOrder/GetPendingObjectionList';
public readonly SaveSettlePaymentApi = 'api/TblOrder/SaveSettlePayment';
public readonly SaveSettlePartialPaymentApi = 'api/TblOrder/SaveSettlePartialPayment'; 
public readonly PassCreditSaleToBHApi = 'api/TblOrder/PassCreditSaleToBH';
public readonly SavePackConfirmApi = 'api/TblOrder/SavePackConfirm';
public readonly RectOnCompensationApi = 'api/TblOrder/RectOnCompensation';
//#endregion

// #region Income
public readonly SaveIncomeApi = 'api/Master/SaveIncome';
public readonly GetIncomeDetailByIdApi = 'api/Master/GetIncomeDetailById';
public readonly GetIncomeListApi = 'api/Master/GetIncomeList';
public readonly UpdateIncomeApi = 'api/Master/UpdateIncome';
public readonly DeleteIncomeApi = 'api/Master/DeleteIncome';
//#endregion

// #region Feedback
public readonly UpdateFeedbackApi = 'api/Master/UpdateFeedback';
public readonly SaveFeedbackApi = 'api/Master/SaveFeedback';
public readonly GetFeedbackListApi = 'api/Master/GetFeedbackList';
public readonly GetFeedbackDetailApi = 'api/Master/GetFeedbackDetail';
//#endregion

// #region Report
public readonly RptIncomeApi = 'api/Report/RptIncome';
public readonly RptSalesApi = 'api/Report/RptSales';
public readonly RptPurchaseApi = 'api/Report/RptPurchase';
public readonly RptCashManagementApi = 'api/Report/RptCashManagement';
public readonly RptCashManagementAdminApi = 'api/Report/RptCashManagementAdmin';
public readonly RptSellCollectionAPi = 'api/Report/RptSellCollection';
public readonly RptProductRequestApi = 'api/Report/RptProductRequest';
public readonly GetGraphDataApi = 'api/Report/GetGraphData';
public readonly GetDashboardCountApi = 'api/Report/GetDashboardCount';
public readonly ExportTankHistoryApi = 'api/Report/ExportTankHistory';
public readonly RptCategoryApi = 'api/Report/RptCategory';
public readonly RptTechnicianSaleApi = 'api/Report/RptTechnicianSale';
public readonly RptCashExpenseApi = 'api/Report/RptCashExpense';


//#endregion

// ENCRYPT AND DECRYPT
  encrypt(text: any) {
    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
  }
  decrypt(text: any) {
    return CryptoJS.AES.decrypt(text, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }
// END
}