import { Component, AfterViewInit, OnInit, Compiler } from '@angular/core';
import { AppConfig } from './app.config';
import { MatDialog } from '@angular/material';
import { Enquiry, Subscribe } from './models';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from './services';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit  {

  componentloading: boolean;
  menu: any;
  ActiveMenuid: number;
  master = false;
  loading = false;
  title = 'app';
  userid: any;
  roleid: any;
  Users: any;
  username: any;
  designation: any;
  email: any;
  sso: string;
  notificationcount: any = 0;
  notificationlist: any;
  workforname: string = '';
  role: string = '';
  CurrentUrl: String = '';

  constructor(private service: UserService, private route: Router,
    private appconfig: AppConfig, public dialog: MatDialog, private appcompiler: Compiler) {
    this.componentloading = true;
    const userinfo = localStorage.getItem('currentUser');
    if (userinfo) {
      const decryptuserinfo: any = this.appconfig.decrypt(userinfo);
      this.Users = JSON.parse(decryptuserinfo);
      if (this.Users) {
        this.master = true;
        this.username = this.Users.username;
        this.designation = this.Users.designation;
        this.role = this.Users.role;
      }
    }
    this.sso = this.getCookie('dotJM');
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.route.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.appcompiler.clearCache();
          this.componentloading = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.componentloading = false;
        }
        let UrlData: any = event;
        this.CurrentUrl = UrlData.url;
      });
  }

  logout() {
    if (this.sso !== 'null' && this.sso !== '') {
      localStorage.removeItem('currentUser');
    } else {
      localStorage.removeItem('currentUser');
      this.setCookie('dotJM', '', 1);
    }
    window.location.href = '/';
  }

  routetolink(page) {
    if (page !== '') {
      this.route.navigate([page]);
    }
  }
  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }
  
  public getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
